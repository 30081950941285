@use '_modules' as *;

join-the-team {
  position: relative;
  box-shadow: none !important;

  > div {
    width: 100%;
    text-align: center;
    padding: $spacer2 $gap;

    h3 {
      font-size: 25px;
    }

    h4 {
      font-size: 18px;
    }

    h3,
    p {
      color: $white;
    }

    h4 {
      color: $red;
    }

    p {
      margin: $gutter;
    }
  }

  > picture.image {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    width: 100vw;
    margin-left: -50vw;
    z-index: -1;
  }

  @media (min-width: 768px) {
    width: 60%;
    margin: 0 auto;

    h3 {
      font-size: 32px;
    }

    h4 {
      font-size: 25px;
    }
  }
}
